import React, { useRef, useEffect, useState } from "react";
import Img from "gatsby-image";
import { useInView } from 'react-intersection-observer';

import SplitText from '../vendor/SplitTextPlugin';
import gsap from "gsap";

const ProfileBlock = (props) => {

    const refText = useRef();
    const refImage = useRef();
    const refLinkMollie = useRef();
    const refLinkPartner = useRef();
    const [tl] = useState(gsap.timeline({ paused: true }));
    const [ref, inView] = useInView({ triggerOnce: true, rootMargin: '-30% 0px' })  
    
    useEffect(() => {
        const paragraph = new SplitText(refText.current, { type: 'words' });
        const elements = paragraph.words.concat(refLinkMollie.current, refLinkPartner.current);
        tl.fromTo(refImage.current, 1, { y: 100, opacity: 0 }, { y: 0, opacity: 1, ease: 'Power3.easeInOut'});
        tl.staggerFromTo(elements, 0.5, { y: 20, opacity: 0}, { y: 0, opacity: 1, ease: 'Power3.easeInOut'}, 0.01, '<0.3');
    }, [tl]);

    useEffect(() => {
        if (inView) {
            tl.play();
        }
    }, [inView, tl]);

    return (
        <section className={`profileBlock ${props.theme}`} ref={ref}> 
            <div className="profileBlock-Wrapper">
                <div className={` profileBlock-Image profileBlock-Image_${props.theme}`}>
                    <div ref={refImage}>
                        <Img
                            fluid={props.image}
                            imgStyle={{
                                objectFit: "contain",
                                objectPosition: "center center",
                                width: "calc(100% - 120px)",
                            }}
                        />
                    </div>
                </div>
                <div className="profileBlock-Text">
                    <div className="profileBlock-Text_Text type-Profile" ref={refText} dangerouslySetInnerHTML={{__html: props.text}} />
                    <div className="profileBlock-Logos logoBlock">
                        <div ref={refLinkMollie}>
                            <a href="https://www.mollie.com/en/" target="_blank" rel="noopener noreferrer">
                                <img src={props.iconLeft} alt="Logo Mollie" />
                            </a>
                        </div>
                        <div ref={refLinkPartner}>
                            <a href={props.websitePartner} target="_blank" rel="noopener noreferrer">
                                <img src={props.iconRight} alt={"Logo " + props.namePartner} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ProfileBlock

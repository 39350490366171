import React, { useRef, useEffect, useState } from "react";
import gsap from "gsap";
import { useInView } from 'react-intersection-observer';
import SplitText from '../vendor/SplitTextPlugin';

import TrustedPartnerBlock from "../components/TrustedPartnerBlock";

const TrustBlock = (props) => {
    
    const refTitle = useRef();
    const [tl] = useState(gsap.timeline({ paused: true }));
    const [ref, inView] = useInView({ triggerOnce: true })  

    useEffect(() => {
        const titleLines = new SplitText(refTitle.current, { type: 'words' });
        new SplitText(refTitle.current, { type: 'words' });

        tl.staggerFromTo(titleLines.words, 0.8, { y: 300 }, { y: 0, ease: 'Power1.easeInOut'}, 0.2);
    }, [tl]);

    useEffect(() => {
        if (inView) {
            tl.play();
        }
    }, [inView, tl]);

    return (
        <section className="trustBlock">
            <div className="contentBlock">
                <div className="contentBlock-Content trustBlock trustBlock-Header" ref={ref}>
                    <h2 className="type-TrustBlockHeader" ref={refTitle}>{props.copy}</h2>
                </div>

                <TrustedPartnerBlock trustedPartnerList={props.trustedPartnersList}/>
            </div>
        </section>
    )
}

export default TrustBlock;
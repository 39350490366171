import EventDispatcher from '../modules/EventDispatcher';

class ResizeModule extends EventDispatcher {
    constructor() {
        super();
      
        this._onResize = this._onResize.bind(this);

        this._setupEventListeners();
        this._setSize();
    }

    get width() {
        return this._size.width;
    }
    
    get height() {
        return this._size.height;
    }
    
    _setupEventListeners() {
        window.addEventListener('resize', this._onResize);
    }
    
    _setSize() {
        this._size = {};
        this._size.width = window.innerWidth;
        this._size.height = window.innerHeight;
    }
    
    _dispatch() {
        this.dispatch('resize', this._size);
    }
    
    _onResize() {
        this._setSize();
        this._dispatch();
    }
}
  
export const resizeModule = new ResizeModule();
import React from "react";
import gsap from 'gsap';
import SplitText from '../vendor/SplitTextPlugin';

import ParallaxModule from '../modules/ParallaxModule';

class LandingSection extends React.Component {
    constructor(props) {
        super(props);

        this._refHeadline = [];
    }

    componentDidMount() {
        this._setupAnimation();
    }

    _setupAnimation() {
        new SplitText(this.refText, { type: 'lines', linesClass: 'title-mask' });
        const titleWords = new SplitText(this.refText, { type: 'words' });

        const tlIn = gsap.timeline();  
        tlIn.staggerFromTo(titleWords.words, 1.2, { y: 300 }, { y: 0, ease: 'Circ.easeInOut' }, 0.1);

        const tlOut = gsap.timeline({ paused: true });
        tlOut.to(this._refHeadlineWrapper, 1, { y: -200 });

        new ParallaxModule({ 
            el: null,
            tl: tlOut,
            offset: 0
        });
    }

    render() {
        return (
            <section className="landingBlock scrollSection" ref={el => this._refBlock = el} >
                <div className="textBlock landingtoVisible">
                    <div className="textBlock-Content">
                        <h1 id="typeEffectVertical" className="type-Landing" ref={el => this._refHeadlineWrapper = el}>
                            <div ref={el => this.refText = el}>{this.props.landingCopy}</div>
                        </h1>
                    </div>
                </div>
            </section>
        )
    }
}

export default LandingSection;

import React, { useRef, useState, useEffect } from 'react';

import { useInView } from 'react-intersection-observer';
import Img from "gatsby-image";
import TitleBlock from "../components/TitleBlock";

import SplitText from '../vendor/SplitTextPlugin';
import gsap from 'gsap';

const CollaborationSection = (props) => {

    const refTitle = useRef();
    const [tl] = useState(gsap.timeline({ paused: true }));
    const [ref, inView] = useInView({ triggerOnce: true, rootMargin: '-30% 0px' })  

    useEffect( () =>  {
        const splitChars = new SplitText(refTitle.current, { type: 'chars' });
        new SplitText(refTitle.current, { type: 'lines, chars'});
        
        tl.staggerFromTo(splitChars.chars, 0.6, { y: 200, opacity: 0 }, { y: 0, opacity: 1, ease: 'Power1.easeInOut'}, 0.05);
    }, [tl]);

    useEffect( () =>  {
        if (inView) {
            tl.play();
        }
    }, [inView, tl]);

    return (
        <section className="darkBg" ref={ref}>
            <div className="imageBgBlock">
                <Img fluid={props.image} />
            </div>
            <TitleBlock title={props.text} typeClass="Collaboration" propsRefTitle={refTitle} />
        </section>
    )

}

export default CollaborationSection;
import React, { useRef, useState, useEffect } from 'react';

import { useInView } from 'react-intersection-observer';
import SplitText from '../vendor/SplitTextPlugin';
import gsap from 'gsap';

const BecomePartnerBlock = (props) => {

    const refTitle = useRef();
    const refButtonMollie = useRef();
    const refButtonPartner = useRef();
    const [tl] = useState(gsap.timeline({ paused: true }));
    const [ref, inView] = useInView({ triggerOnce: true, rootMargin: '-30% 0px' })  

    useEffect( () =>  {
        new SplitText(refTitle.current, { type: 'lines', linesClass: 'title-mask' });
        const splitChars = new SplitText(refTitle.current, { type: 'words' });
        
        tl.staggerFromTo(splitChars.words, 0.6, { y: 200, opacity: 0 }, { y: 0, opacity: 1, ease: 'Power1.easeInOut'}, 0.05);
        tl.staggerFromTo([refButtonMollie.current, refButtonPartner.current], 0.6, { opacity: 0 }, { opacity: 1, ease: 'Power1.easeInOut'}, 0.1, '>-0.1');
    }, [tl]);

    useEffect( () =>  {
        if (inView) {
            tl.play();
        }
    }, [inView, tl]);

    return (
        <section className="referralBlock darkBg black">
            <div className="textBlock referralBlock-Header">
                <div className="textBlock-Content" ref={ref}>
                    <p className="type-ReferralHeader" ref={refTitle}>{props.header}</p>
                    <div className="referralBlock-Logos">
                        <a href="https://www.mollie.com/en/" target="_blank" rel="noopener noreferrer" className="button button-main button-main--border" ref={refButtonMollie}>
                            <img src={props.logoMollie.file.url} alt="Logo Mollie" height="22" />
                        </a>
                        <a href={props.websitePartner} target="_blank" rel="noopener noreferrer" className="button button-main button-main--border" ref={refButtonPartner}>
                            <img src={props.logoPartner.file.url} alt={"Logo " + props.namePartner}  height="22"/>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BecomePartnerBlock

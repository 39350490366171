import React, { useRef, useState, useEffect } from "react";
import Img from "gatsby-image";
import gsap from 'gsap';

import ParallaxModule from '../modules/ParallaxModule';

import SplitText from '../vendor/SplitTextPlugin';
import { useInView } from 'react-intersection-observer';

const WorkTogetherSection = (props) => {

    const refText = useRef();
    const refImage = useRef();
    const [tl] = useState(gsap.timeline({ paused: true }));
    const [ref, inView] = useInView({ triggerOnce: true, rootMargin: '-30% 0px' });

    useEffect(() => {
        const tlParallax = gsap.timeline({ paused: true});
        tlParallax.fromTo(refImage.current, 2, { yPercent: 100 }, { yPercent: 0 });

        new ParallaxModule({
            el: refImage.current,
            tl: tlParallax,
            offset: 0,
            offsetY: 1.3
        });
          
        const textLines = new SplitText(refText.current, { type: 'lines' });
        new SplitText(refText.current, { type: 'lines', linesClass: 'title-mask' });
        tl.staggerFromTo(textLines.lines, 0.5, { y: 100 }, { y: 0, ease: 'Circ.easeInOut'}, 0.1, '<0.3');
    }, [tl]);

    useEffect(() => {
        if (inView) {
            tl.play();
        }
    }, [inView, tl]);

    return (
        <section className="workTogetherBlock lightBg" ref={ref}>
            <div className="workTogetherBlock-Images">
                <div className="workTogetherBlock-Image" ref={refImage}>
                    <Img className="image-workTogetherBlock" fluid={props.image} />
                </div>
            </div>
            <div className="textBlock">
                <div className="textBlock-Item">
                    <p className="type-workTogetherBlock" ref={refText}>{props.text}</p>
                </div>
            </div>
        </section>
    )
}

export default WorkTogetherSection;

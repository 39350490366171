class EventDispatcher {
    constructor() {
        this._listeners = {};
    }
    
    add(eventName, listener) {
        if (!this._listeners[eventName]) this._listeners[eventName] = [];
        this._listeners[eventName].push(listener);
    }
    
    dispatch(eventName, payload = {}) {
        if (!this._listeners[eventName]) return;
        const limit = this._listeners[eventName].length;
        let index, listener;
        for (index = 0; index < limit; index++) {
            listener = this._listeners[eventName][index];
            listener.call(this, payload);
        }
    }
    
    remove(eventName, listener) {
        if (!this._listeners[eventName]) return; 

        const index = this._listeners[eventName].indexOf(listener);
        this._listeners[eventName].splice(index, 1);
    }
}

export default EventDispatcher;
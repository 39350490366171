import React, { useRef, useEffect } from 'react';
import gsap from 'gsap';
import Img from "gatsby-image";

import WhatTextBlock from "./WhatTextBlock";
import TitleHeaderBlock from "./TitleHeaderBlock";

import ParallaxModule from '../modules/ParallaxModule';
import WhyBlock from "./WhyBlock";

const WhatBlock = (props) => {

    const refBlock = useRef();

    const refImage = useRef();
    const refImageWrapper = useRef();
    const refImageWrapperSwitch = useRef();

    const refTextBlock = useRef();
    const refWhyTitle = useRef();

    useEffect(() => {
        const tl = gsap.timeline({ paused: true });
        tl.set(refImageWrapperSwitch.current, { opacity: 0 });
        tl.fromTo(refImage.current, 1, { clipPath: 'inset(100% 21% 0%)' }, {  clipPath: 'inset(0% 0% 0%)' });
        tl.fromTo(refImageWrapper.current, 1, { scale: 1.5 }, { scale: 1 }, '-=1');

        let animationData = {
            el: refImage.current,
            tl: tl,
            offset: 0
        }
        new ParallaxModule(animationData);
    }, []);

    useEffect(() => {
        const tl = gsap.timeline({ paused: true });
        tl.to(refImageWrapper.current, 1, { scale: 1.2 });
        
        let animationData = {
            el: refTextBlock.current,
            tl: tl,
            offset: 0,
            offsetY: 0.5
        }
        new ParallaxModule(animationData);
    }, []);

    useEffect(() => {
        const tl = gsap.timeline({ paused: true });
        tl.fromTo(document.body, 0.1, { backgroundColor: '' }, { backgroundColor: '#000' });
        tl.to(refImage.current, 10, { clipPath: 'inset(25% 40%)' });
        tl.to(refImageWrapper.current, 10, { scale: 0.6 }, '<');
        tl.to(refImage.current, 20, { xPercent: 30, yPercent: -30 });
        tl.to(refImageWrapper.current, 10, { opacity: 0 }, '<10');
        tl.to(refImageWrapperSwitch.current, 10, { opacity: 1 }, '<');
        // tl.fromTo(this.refWhyBlockImage.current, 5, { yPercent: 100 }, { yPercent: 0 });

        let animationData = {
            el: refWhyTitle.current,
            tl: tl,
            offset: 0,
            offsetY: 0
        }
        new ParallaxModule(animationData);
    }, []);
    
    return (
        <section id="whatBlock" className="whatBlock scrollSection" ref={refBlock}>
            <div ref={refImage} className="whatBlock-Image" >
                <div ref={refImageWrapper} className="whatBlock-Wrapper">
                    <Img fluid={props.whatImage.fluid} />
                </div>
                <div ref={refImageWrapperSwitch} className="whatBlock-Wrapper whatBlock-Wrapper--switch">
                    <Img fluid={props.whyImageRight.fluid} />
                </div>
            </div>
            <div className="whatBlock-Content">
                <TitleHeaderBlock
                    title={props.whatTitleCopy}
                    typeClass="WhatLanding" />

                {/* <TextBlock
                    html={props.whatCopy.childMarkdownRemark.html} 
                    typeClass="WhatText" 
                    refProps={refTextBlock} /> */}

                <div className="textBlock whatBlock-Middle" ref={refTextBlock}>
                    <WhatTextBlock text={props.whatCopy.childMarkdownRemark.html}/>
                </div>

                <TitleHeaderBlock title={props.whyTitleCopy}
                    refProps={refWhyTitle}
                    typeClass="WhyLanding" />
                
                <WhyBlock
                    whyCopy={props.whyCopy}
                    imageLeft={props.whyImageLeft.fluid}
                    imageRight={props.whyImageRight.fluid}/>
            </div>
        </section>
    )
}

export default WhatBlock;
import React from "react"
import TrustedPartnerComponent from './TrustedPartnerComponent';

import '../styles/trusted-partner.scss';

const TrustedPartnerBlock = (props) => {
    
    const TrustedPartnerBlocks = (props) => {
        const elements = []
        for (const [index, element] of props.trustedPartnerList.entries()) {
            elements.push(<TrustedPartnerComponent {...props} element={element} key={index} index={index} />);
        }
        return elements;
    }

    return (
        <div className="contentBlock-Content trustBlock trustBlock-Hyperlink">
            <TrustedPartnerBlocks {...props}/>
        </div>
    )
}

export default TrustedPartnerBlock

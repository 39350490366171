import React, { useRef, useEffect, useState } from "react";
import gsap from 'gsap';
import SplitText from '../vendor/SplitTextPlugin';
import { useInView } from 'react-intersection-observer';

const TitleBlock = (props) => {

    const refTitle = useRef();
    const [tl] = useState(gsap.timeline({ paused: true }));
    const [ref, inView] = useInView({ rootMargin: '-40% 0px'});

    useEffect( () =>  {
        const splitChars = new SplitText(refTitle.current, { type: 'chars' });
        new SplitText(refTitle.current, { type: 'lines, chars' });
        tl.staggerFromTo(splitChars.chars, 0.6, { y: 100, opacity: 0 }, { y: 0, opacity: 1, ease: 'Power1.easeInOut'}, 0.05);
    }, [tl]);

    useEffect( () =>  {
        if (inView) {  
            tl.play();
        }
    }, [inView, tl]);

    return (
        <div className="titleBlock titleBlock-Header" ref={props.refProps}>
            <div className="titleBlock-Item" ref={ref}>
                <h2 className={`fadeIn-${props.typeClass} type-${props.typeClass}`} ref={refTitle}>{props.title}</h2>
            </div>
        </div>
    )
}

export default TitleBlock
  
import React from "react"
import Img from "gatsby-image"

import '../styles/footer.scss';

function Footer(props) {
  const elements = []
  for (const [index, element] of props.iconList.entries()) {
    elements.push(
      <a href={element.hyperlink} key={index} target="_blank" rel="noopener noreferrer" aria-label={element.title}>
        <Img fluid={element.icon.fluid} />
      </a>
    )
  }
  return (
    <footer className="footer">
      <div className="footer-Wrapper">
        <div className="footer-Logo">
          <img src={props.logo} alt="Logo Mollie" />
        </div>
        <div className="footer-Copyright type-Copyright">
          {props.copyright}
        </div>
        <div className="footer-Icons">{elements}</div>
      </div>
    </footer>
  )
}

export default Footer

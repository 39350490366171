import { smoothScrollModule } from '../modules/SmoothScrollModule';
import { resizeModule } from '../modules/ResizeModule';

const range = function(value, input = {}, output = {}) {
    return (value - input.min) * (output.max - output.min) / (input.max - input.min) + output.min;
}

const clamp = function(value, min, max) {
    return Math.min(Math.max(value, min), max);
}

class ParallaxModule {
    
    constructor(options = {}) {
        this._el = options.el;
        this._tl = options.tl;
        this._off = options.offset;
        this._offY = (options.offsetY) ? options.offsetY : 0;
        this._isTrigger = options.isTrigger === undefined ? false : options.isTrigger;

        this._onScroll = this._onScroll.bind(this);

        this._setupListeners();
        this._setBounds();
        this._setOffset();
        this._setProgress();
        this._setTransform();
    }

    _setupListeners() {
        smoothScrollModule.add('scroll', this._onScroll);
        resizeModule.add('scroll', this._onResize);
    }
     
    _setBounds() {
        if (this._el !== null) {
            this._bounds = this._el.getBoundingClientRect();
        }
    }
     
    _setOffset() {
       this._offset = {};
       this._offset.top = (this._el === null) ? 0 : (this._bounds.top - (resizeModule.height * this._off) - (resizeModule.height * this._offY));
       this._offset.bottom = (this._el === null) ? window.innerHeight : (this._bounds.top + this._bounds.height) - (resizeModule.height * this._off) - (resizeModule.height * this._offY);
       this._offset.height = (this._el === null) ? window.innerHeight : this._el.clientHeight;
    }    
     
    _setProgress() {    
       const input = { min: this._offset.top, max: this._offset.bottom };
       const output = { min: 0.0, max: 1.0 };
          
       this._progress = clamp(range(smoothScrollModule.y, input, output), 0.0, 1.0);
    }
    
    _setTransform() {
        this._tl.progress(this._progress + this._off);
    }
    
    /* 
    * Handlers 
    */
    _onScroll() {
        this._setProgress();
        this._setTransform();
    }

    _onResize() {
        this._setOffset();
    }

}

export default ParallaxModule;
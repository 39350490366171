import React, { useRef, useEffect, useState } from "react";
import Img from "gatsby-image"

import { useInView } from 'react-intersection-observer';

import SplitText from '../vendor/SplitTextPlugin';
import gsap from "gsap";

const WhyBlock = (props) => {

    const refText = useRef();

    const [tl] = useState(gsap.timeline({ paused: true }));
    const [ref, inView] = useInView({ triggerOnce: true })  
    
    useEffect(() => {
        new SplitText(refText.current, { type: 'words', linesClass: 'title-mask' });
        const titleWords = new SplitText(refText.current, { type: 'words' });

        tl.staggerFromTo(titleWords.words, 1, { y: 100, opacity: 0 }, { y: 0, opacity: 1, ease: 'Power3.easeInOut' }, 0.1);
    }, [tl]);

    useEffect(() => {
        if (inView) {
            tl.play();
        }
    }, [inView, tl]);

    return (
        <section className="whyBlock" ref={props.refWhyBlock}>
            <div className="whyBlock-Title" ref={ref}>
                <p className="type-whyBlock" ref={refText} dangerouslySetInnerHTML={{
                    __html: props.whyCopy,
                    }} />
            </div>
            <div className="whyBlock-Images">
                <div className="whyBlock-Image" ref={props.refWhyBlockImage}>
                    <Img fluid={props.imageLeft} />
                </div>
            </div>
            {/* <TextBlock html={props.html} typeClass="whyBlock" refTextBlockTitle={props.refWhyBlockText}/> */}
            {/* <TitleBlock title={props.text} typeClass="JustAsk" propsRefTitle={refTitle} /> */}
        </section>
    )
}

export default WhyBlock;

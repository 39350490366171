import React, { useRef, useEffect, useState } from "react";
import gsap from "gsap";
import Img from "gatsby-image";

import { useInView } from 'react-intersection-observer';
import SplitText from '../vendor/SplitTextPlugin';

const ReferralBlock = (props) => {

    const refText = useRef();
    const refCaption = useRef();
    const refOtherImage = useRef();
    const refHeroImage = useRef();

    const [tlHero] = useState(gsap.timeline({ paused: true }));
    const [refHero, inViewHero] = useInView({ triggerOnce: true });

    const [tl] = useState(gsap.timeline({ paused: true }));
    const [ref, inView] = useInView({ triggerOnce: true });

    useEffect(() => {
        tlHero.fromTo(refHeroImage.current, 1, { y: window.innerHeight/2, opacity: 0}, { y: 0, opacity: 1, ease: 'Circ.easeInOut'});
    }, [tlHero]);

    useEffect(() => {
        if (inViewHero) {
            tlHero.play();
        }
    }, [inViewHero, tlHero]);

    useEffect(() => {
        const titleLines = new SplitText(refText.current, { type: 'words' });
        const elements = titleLines.words.concat(refCaption.current, refOtherImage.current);
        tl.staggerFromTo(elements, 0.5, { y: 20, opacity: 0}, { y: 0, opacity: 1, ease: 'Power3.easeInOut'}, 0.01);
    }, [tl]);

    useEffect(() => {
        if (inView) {
            tl.play();
        }
    }, [inView, tl]);

    return (
        <section className="referralBlock darkBg black">
            <div className="referralBlock-Landing" ref={refHero}>
                <div ref={refHeroImage}>
                    <Img fluid={props.landingImage} />
                </div>
            </div>
            <div className="referralBlock-Content" ref={ref}>
                <div className="referralBlock-Text">
                    <div
                        ref={refText}
                        className="type-ReferralText"
                        dangerouslySetInnerHTML={{
                            __html: props.text,
                        }}
                    />
                    <div
                        ref={refCaption}
                        className="type-ReferralSignature"
                        dangerouslySetInnerHTML={{
                            __html: props.textCaption,
                        }}
                    />
                    <a href={props.ctaLink} className="button button-main referralBlock-cta" target="_blank" rel="noopener noreferrer">
                       {props.ctaText}
                    </a>
                </div>
                <div className="referralBlock-Image" ref={refOtherImage}>
                    <Img
                        fluid={props.thumbnail}
                        imgStyle={{
                            objectFit: "contain",
                            objectPosition: "bottom center",
                        }}
                    />
                </div>
            </div>
        </section>
    )
}

export default ReferralBlock

import React, { useRef, useEffect, useState } from 'react';
import Img from "gatsby-image";

import gsap from 'gsap';
import { useInView } from 'react-intersection-observer';

const WIDTH_IMAGE = 420;

const TrustedPartnerComponent = (props) => {

    let blockData = {};

    const refBlock = useRef();
    const refImage = useRef();
    const refTitle = useRef();
    const refButton = useRef();
    const refLine = useRef();

    const [tl] = useState(gsap.timeline({ paused: true }));
    const [ref, inView] = useInView({ triggerOnce: true, rootMargin: '50% 0px' });

    const onMouseEnter = (e) => {
        const img = refBlock.current.querySelector('img');
        blockData.left = refBlock.current.getBoundingClientRect().left;
        blockData.height = img.offsetHeight;
        gsap.fromTo(refImage.current, 0.5, { scale: 0.8, opacity: 0 }, { scale: 1, opacity: 1, ease: 'Power3.easeInOut' });
    }
      
    const onMouseMove = (e) => {
        const yPos = blockData.height;
        const xPos = e.pageX - blockData.left;
        gsap.to(refImage.current, 0.2, { x: xPos - (WIDTH_IMAGE/2), y: -(yPos/2) });
    }
    
    const onMouseOut = (e) => {
        gsap.to(refImage.current, 0.5, { scale: 0.9, opacity: 0, ease: 'Power3.easeInOut' });
    }

    useEffect(() => {
        tl.fromTo(refTitle.current, 0.5, {opacity: 0 }, { opacity: 1, ease: 'Power3.easeInOut'});
        tl.fromTo(refButton.current, 0.5, { opacity: 0 }, { opacity: 1, ease: 'Power3.easeInOut'}, '<0.2');
        tl.fromTo(refLine.current, 0.5, { scaleX: 0 }, { scaleX: 1, ease: 'Power3.easeInOut'}, '<0.1');
    }, [tl]);

    useEffect(() => {
        if (inView) {
            tl.play();
        }
    }, [inView, tl]);

    return (
        <button className="button trustBlock-Hyperlink__item" ref={refBlock} onMouseEnter={onMouseEnter}  onMouseMove={onMouseMove} onMouseLeave={onMouseOut}>
            <div className="trustBlock-Hyperlink_Title" ref={ref}>
                <p className="type-TrustBlockLink bold" ref={refTitle}>{props.element.name}</p>
            </div>
            <div className="trustBlock-Hyperlink_Image-wrapper">
                <div className="trustBlock-Hyperlink_Image-inner" ref={refImage}>
                    <Img className="trustBlock-Hyperlink_Image" fluid={props.element.thumbnail.fluid} />
                </div>
            </div>
            <svg className="trustBlock-Hyperlink__item-icon" viewBox="0 0 58 58">
                <g fill="none" fillRule="evenodd">
                    <path d="M38.3536,29.0355394 C38.5488,28.8402694 38.5488,28.5236894 38.3536,28.3284294 L35.1716,25.1464474 C34.9763,24.9511844 34.6597,24.9511844 34.4645,25.1464464 C34.2692,25.3417094 34.2692,25.6582914 34.4645,25.8535494 L37.2929,28.6819794 L34.4645,31.5104094 C34.2692,31.7056694 34.2692,32.0222494 34.4645,32.2175194 C34.6597,32.4127794 34.9763,32.4127794 35.1716,32.2175194 L38.3536,29.0355394 Z M19,29.1819794 L38,29.1819794 L38,28.1819794 L19,28.1819794 L19,29.1819794 Z" fill="#000" fillRule="nonzero" />
                    <circle strokeOpacity=".1" stroke="#000" strokeWidth="2" cx="29" cy="29" r="28"/>
                </g>
            </svg>
            <a href={props.element.hyperlink} ref={refButton} target="_blank" rel="noopener noreferrer" className="button contentDesktop button-main trustBlock-Hyperlink_Link">
                Go to the website
            </a>
            <div className="trustBlock-Hyperlink__item-line" ref={refLine}></div>
        </button>
    )
}

export default TrustedPartnerComponent;


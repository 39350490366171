import React, { useRef, useEffect, useState } from "react";

import { useInView } from 'react-intersection-observer';

import SplitText from '../vendor/SplitTextPlugin';
import gsap from "gsap";

const WhatTextBlock = (props) => {

    const refText = useRef();

    const [tl] = useState(gsap.timeline({ paused: true }));
    const [ref, inView] = useInView({ triggerOnce: true })  
    
    useEffect(() => {
        new SplitText(refText.current, { type: 'words', linesClass: 'title-mask' });
        const titleWords = new SplitText(refText.current, { type: 'words' });

        tl.staggerFromTo(titleWords.words, 1, { y: 100, opacity: 0 }, { y: 0, opacity: 1, ease: 'Power3.easeInOut' }, 0.1);
    }, [tl]);

    useEffect(() => {
        if (inView) {
            tl.play();
        }
    }, [inView, tl]);

    return (
        <div className="whyBlock-Title" ref={ref}>
            <p ref={refText} className="type-whyBlock" dangerouslySetInnerHTML={{
                __html: props.text,
            }} />
        </div>
    )
}

export default WhatTextBlock;

import React from "react";
import gsap from 'gsap';

import { resizeModule } from '../modules/ResizeModule';

import '../styles/header.scss';

const HEADER_ACTIVE_CLASS = 'header--light';
class Header extends React.Component {

    constructor() {
        super();

        
        this._onScroll = this._onScroll.bind(this);
        this._onResize = this._onResize.bind(this);
    }
    
    componentDidMount() {
        this._darkSections = document.querySelectorAll(".darkBg");
        this._lightSections = document.querySelectorAll(".lightBg");
        this._whatBlockSection = document.querySelector(".whatBlock");

        this._setupListeners();
        this._animateIn();
    }

    _setupListeners() {
        this._onResize();

        resizeModule.add('resize', this._onResize);
        window.addEventListener('scroll', this._onScroll);
        // smoothScrollModule.add('scroll', this._onScroll);
    }

    _animateIn() {
        const tl = gsap.timeline({ delay: 2});
        tl.staggerFromTo([this.refLogoDesktop.children, this.refButton], 0.8, { opacity: 0, y: 20 }, { opacity: 1, y: 0, ease: 'Power3.easeInOut' }, 0.1);
        tl.staggerFromTo(this.refLogoMobile.children, 0.8, { opacity: 0, y: 20 }, { opacity: 1, y: 0, ease: 'Power3.easeInOut' }, 0.1, '<');
        // tl.fromTo(this.refButton, 0.8, { opacity: 0, y: 20 }, { opacity: 1, y: 0, ease: 'Power3.easeInOut' });
    }
    
    _updateMenu() {
        this._darkSections.forEach((el) => {
            let bounding = el.getBoundingClientRect();
            if (bounding.top < this._objData.headerCenter && bounding.bottom > this._objData.headerCenter) {
                this.refHeader.classList.add(HEADER_ACTIVE_CLASS);
            }
        });
  
        this._lightSections.forEach((el) => {
            let bounding = el.getBoundingClientRect();
            if (bounding.top < this._objData.headerCenter && bounding.bottom > this._objData.headerCenter) {
                this.refHeader.classList.remove(HEADER_ACTIVE_CLASS);
            }
        });

        const currentScroll = document.documentElement.scrollTop;

        if (currentScroll < this._objData.whatBlockHeight) {
            if (currentScroll > ( window.innerHeight - 200)) {
                this.refHeader.classList.add(HEADER_ACTIVE_CLASS);
            } else {
                this.refHeader.classList.remove(HEADER_ACTIVE_CLASS);
            }
        }
    }

    /*
    * Handlers
    */
    _onScroll() {
        this._updateMenu();
    }

    _onResize() {
        this._objData = {
            headerCenter: this.refButton.getBoundingClientRect().height / 2 + this.refButton.getBoundingClientRect().top,
            whatBlockOffset: this._whatBlockSection.getBoundingClientRect(),
            whatBlockHeight: this._whatBlockSection.offsetHeight,
        }
    }

    render() {
        return (
            <header className="header" ref={el => this.refHeader = el}>
                <div className="header-Logos contentDesktop logoBlock" ref={el => this.refLogoDesktop = el}>
                    <div>
                        <img src={this.props.logoMollieBlack} alt="Logo Mollie Black" className="header-Logos__black"/>
                        <img src={this.props.logoMollieWhite} alt="Logo Mollie White" className="header-Logos__white"/>
                    </div>
                    <span>x</span>
                    <div>
                        <img src={this.props.logoPartnerBlack} alt={'Logo ' + this.props.namePartner + ' Black'} className="header-Logos__black"/>
                        <img src={this.props.logoPartnerWhite} alt={'Logo ' + this.props.namePartner + ' White'} className="header-Logos__white"/>
                    </div>
                </div>
                <div className="header-Logos contentMobile" ref={el => this.refLogoMobile = el}>
                    <p>Mollie</p>
                    <p>{this.props.namePartner}</p>
                </div>
                <a href="https://www.mollie.com/dashboard/login?lang=en" className="button button-main header-cta" ref={el => this.refButton = el} target="_blank" rel="noopener noreferrer">
                    Login with mollie
                </a>
            </header>
        )
    }
}

export default Header;

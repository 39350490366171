import React from "react"

function TitleBlock(props) {
  return (
    <div className="titleBlock" ref={props.propsRef}>
      <div className="titleBlock-Item">
        <h2 className={`fadeIn-${props.typeClass} type-${props.typeClass}`} ref={props.propsRefTitle}>
          {props.title}
        </h2>
      </div>
    </div>
  )
}

export default TitleBlock

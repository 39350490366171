import React, { useRef, useEffect, useState } from "react";
import gsap from "gsap";

import SplitText from '../vendor/SplitTextPlugin';
import { useInView } from 'react-intersection-observer';

const QuestionBlock = (props) => {
  
    const refNumber = useRef();
    const refTitle = useRef();
    const refDescription = useRef();
    const [tl] = useState(gsap.timeline({ paused: true }));
    const [refQuestionBlock, inView] = useInView({ triggerOnce: true, rootMargin: '-30% 0px' });

    useEffect(() => {
        const titleLines = new SplitText(refTitle.current, { type: 'lines' });
        new SplitText(refTitle.current, { type: 'lines', linesClass: 'title-mask' });
    
        const descriptionLines = new SplitText(refDescription.current, { type: 'lines' });
        new SplitText(refDescription.current, { type: 'lines', linesClass: 'title-mask' });
        
        tl.staggerFromTo(refNumber.current, 0.5, { y: 100, opacity: 0 }, { y: 0, opacity: 1, ease: 'Circ.easeInOut'}, 0.1, '<');
        tl.staggerFromTo(titleLines.lines, 0.5, { y: 100 }, { y: 0, ease: 'Circ.easeInOut'}, 0.1, '<0.1');
        tl.staggerFromTo(descriptionLines.lines, 0.5, { y: 100 }, { y: 0, ease: 'Circ.easeInOut'}, 0.1, '<0.3');
    }, [tl]);

    useEffect(() => {
        if (inView) {
            tl.play();
        }
    }, [inView, tl]);

    return (
        <div className="questionBlock-Item" key={`props.index`} ref={refQuestionBlock}>
            <div className="questionBlock-Content">
                <div className={`questionBlock-Integer type-QuestionInteger`} ref={refNumber}>
                    {props.data.showNumber &&
                        props.index + 1
                    }
                </div>
                <div className="questionBlock-Text">
                    <h3 className={`questionBlock-Q-${props.index} questionBlock-Q type-QuestionQ`}
                        ref={refTitle}
                        dangerouslySetInnerHTML={{
                            __html: props.data.question.childMarkdownRemark.html,
                        }}
                    ></h3>
                    <div className={`questionBlock-A-${props.index} questionBlock-A type-QuestionA`}
                        ref={refDescription}
                        dangerouslySetInnerHTML={{
                            __html: props.data.answer.childMarkdownRemark.html,
                        }}
                    ></div>
                </div>
            </div>
        </div>
    )
}

export default QuestionBlock;

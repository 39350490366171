import React, { useRef, useEffect } from "react"
import Img from "gatsby-image"
import gsap from "gsap"
// import ParallaxModule from '../modules/ParallaxModule';

import { useInView } from 'react-intersection-observer';

const CounterSection = (props) => {
    const refCountP = useRef();
    const refCountM = useRef();
    const [ref, inView] = useInView({ triggerOnce: true, rootMargin: '-50% 0px' })  
    
    useEffect(() => {
        if (inView) {
            counter(refCountP, props.partnerCount);
            counter(refCountM, props.mollieCount);
        }
    }, [inView, props.partnerCount, props.mollieCount]);

    const counter = (element, newValue) => {
        let counter = { value: 0 };
        gsap.to(counter, 1, { value: newValue, roundProps: "value", onUpdate: () => {
            element.current.textContent = counter.value;
        }});
    }

    return (
        <section className="countBlock lightBg" ref={ref}>
            <div className="countBlock-Wrapper">
                <div className="countBlock-Content">
                    <p className="countP count type-CountInteger" ref={refCountP}>0</p>
                    <p className="type-CountText" dangerouslySetInnerHTML={{__html: props.partnerCopy}}/>
                </div>
            </div>
            <div className="countBlock-Wrapper">
                <Img fluid={props.image} />
                <div className="countBlock-Content">
                    <p className="countM count type-CountInteger" ref={refCountM}>0</p>
                    <p className="type-CountText" dangerouslySetInnerHTML={{__html: props.mollieCopy}}/>
                </div>
            </div>
        </section>
    )
}

export default CounterSection

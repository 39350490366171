import EventDispatcher from '../modules/EventDispatcher';
import gsap from 'gsap';

import { resizeModule } from '../modules/ResizeModule';
import { scrollModule } from '../modules/ScrollModule';

const LERP_MULTIPLIER = 0.1;
const lerp = function(current, target, multiplier) {
    return (1 - multiplier) * current + multiplier * target;
}

class SmoothScrollModule extends EventDispatcher {
    constructor() {
        super();

        this._onResize = this._onResize.bind(this);
        this._onScroll = this._onScroll.bind(this);
        this._onTick = this._onTick.bind(this);

        this._setupEventListeners();
        this._setTarget();
        this._setPosition();
    }

    get x() {
        return this._position.x;
    }

    get y() {
        return this._position.y;
    }

    _setupEventListeners() {
        resizeModule.add('resize', this._onResize);
        scrollModule.add('scroll', this._onScroll);

        gsap.ticker.add(this._onTick);
    }

    _setTarget() {
        this._target = {};
        this._target.x = scrollModule.x;
        this._target.y = scrollModule.y;
    }

    _setPosition() {
        if (!this._position) {
            this._position = {};
            this._position.x = scrollModule.x;
            this._position.y = scrollModule.y;
        }
        
        this._position.x = lerp(this._position.x, this._target.x, LERP_MULTIPLIER);
        this._position.y = lerp(this._position.y, this._target.y, LERP_MULTIPLIER);

        this._dispatch();
    }

    _dispatch() {
        this.dispatch('scroll', this._position);
    }

    /*
    * Handlers
    */
    _onResize() {
        this._setTarget();
        this._setPosition();
    }

    _onScroll() {
        this._setTarget();
    }

    _onTick() {
        this._setPosition();
    }
}

export const smoothScrollModule = new SmoothScrollModule();
import EventDispatcher from '../modules/EventDispatcher';

class ScrollModule extends EventDispatcher {
    constructor() {
        super();

        this._onScroll = this._onScroll.bind(this);

        this._setPosition();
        this._setupEventListeners();
    }
    
    get x() {
        return this._position.x;
    }
    
    get y() {
        return this._position.y;
    }
    
    _setupEventListeners() {
        window.addEventListener('scroll', this._onScroll);
    }
    
    _setPosition() {
        this._position = {};
        this._position.y = window.scrollY;
        this._position.x = window.scrollX;
    }
    
    _dispatch() {
        this.dispatch('scroll', this._position);
    }
    
    _onScroll() {
        this._setPosition();
        this._dispatch();
    }
}
  
export const scrollModule = new ScrollModule();
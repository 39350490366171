import React, { Component } from "react"
import { graphql } from "gatsby"
import get from "lodash/get"
import Footer from "../components/Footer"
import Header from "../components/Header"
import BecomePartnerBlock from "../components/BecomePartnerBlock"
import CounterSection from "../components/CounterSection"
import LandingSection from "../components/LandingSection"
import Layout from "../components/layout"
import ProfileBlock from "../components/ProfileBlock"
import QuestionBlock from "../components/QuestionBlock"
import CollaborationSection from "../components/CollaborationSection"
import ReferralBlock from "../components/ReferralBlock"
import TrustBlock from "../components/TrustBlock"
import NewestFeatureTitle from "../components/NewestFeatureTitle"

import WorkTogetherSection from "../components/WorkTogetherSection"
import SmoothComponent from "../components/SmoothComponent"
import WhatBlock from '../components/WhatBlock';

import SEO from "../components/seo"
import JustAskBlock from "../components/JustAskBlock"

class MolliePage extends Component {

  render() {
    const post = get(this.props, "data.contentfulMollieTemplate")
    
    // Question blocks
    const qAndAList = []
    for (const [index, value] of post.qAndABlockList.entries()) {
      qAndAList.push(<QuestionBlock index={index} data={value} key={index} />)
    }

    // Newest features blocks
    const newestFeaturesBlockList = []
    for (const [index, value] of post.newestFeaturesBlockList.entries()) {
      newestFeaturesBlockList.push(<QuestionBlock index={index} data={value} key={index} />)
    }
    
    // Referral blocks
    const referralList = []
    for (const [index, value] of post.referralBlockList.entries()) {
      referralList.push(<ReferralBlock 
        index={index} 
        landingImage={value.referralLandingImage.fluid}
        thumbnail={value.referralThumbnail.fluid}
        text={value.referralText.childMarkdownRemark.html}
        textCaption={value.referralSignature.childMarkdownRemark.html}
        ctaText={value.referralCtaText}
        ctaLink={value.referralCtaLink}
        key={index} />)
    }

    return (
      <Layout>
        <SEO title="Home" />

        <SmoothComponent
          el='main'
          content='.site-wrapper'
        />

        <Header
          logoMollieBlack={post.logoMollieBlack.file.url}
          logoMollieWhite={post.logoMollieWhite.file.url}
          namePartner={post.namePartner}
          logoPartnerBlack={post.logoPartnerBlack.file.url}
          logoPartnerWhite={post.logoPartnerWhite.file.url}
        />

        <LandingSection 
          landingCopy={post.landingCopy}
        />
        
        <WhatBlock 
          {...post} 
          whyCopy={post.whyCopy} 
          imageLeft={post.whyImageLeft.fluid}
          imageRight={post.whyImageRight.fluid}
          whatTitleCopy={post.whatTitleCopy}
          whyTitleCopy={post.whyTitleCopy}
        />

        <SmoothComponent
          el='.whatBlock'
          content='.whatBlock-Content'
        />

        <div className="site-wrapper">
          <div className="block-pusher"></div>

          <JustAskBlock
            image={post.questionLandingBackground.fluid}
            text={post.questionLandingCopy}
          />

          <section className="questionBlock darkBg black">{qAndAList}</section>

          <CounterSection
            partnerName={post.namePartner}
            partnerCount={post.countPartner}
            partnerCopy={post.countPartnerCopy.childMarkdownRemark.html}
            mollieCopy={post.countMollieCopy.childMarkdownRemark.html}
            mollieCount={post.countMollie}
            image={post.countMollieBackground.fluid}
          />

          <CollaborationSection
            image={post.collaborationBackground.fluid}
            text={post.collaborationCopy}
          />

          <ProfileBlock
            theme="darkBg"
            image={post.profileMolliePortrait.fluid}
            text={post.profileMollieCopy.childMarkdownRemark.html}
            iconLeft={post.logoMollieWhite.file.url}
            iconRight={post.logoPartnerWhite.file.url}
            namePartner={post.namePartner}
            websitePartner={post.websitePartner}
          />
          <ProfileBlock
            theme="lightBg"
            image={post.profilePartnerPortrait.fluid}
            text={post.profilePartnerCopy.childMarkdownRemark.html}
            iconLeft={post.logoMollieBlack.file.url}
            iconRight={post.logoPartnerBlack.file.url}
            namePartner={post.namePartner}
            websitePartner={post.websitePartner}
          />

          <section className="referralBlack-Wrapper darkBg black">{referralList}</section>

          {/* <ReferralBlock
            landingImage={post.referralLandingImage.fluid}
            thumbnail={post.referralThumbnail.fluid}
            text={post.referralText.childMarkdownRemark.html}
            textCaption={post.referralSignature.childMarkdownRemark.html}
          /> */}

          <BecomePartnerBlock
            header={post.becomePartnerCopy}
            logoMollie={post.logoMollieWhite}
            logoPartner={post.logoPartnerWhite}
            namePartner={post.namePartner}
            websitePartner={post.websitePartner}
          />

          <NewestFeatureTitle 
            text={post.newestFeaturesTitle}
          />

          <section className="questionBlock darkBg black">{newestFeaturesBlockList}</section>

          <WorkTogetherSection
            text={post.workTogetherCopy}
            image={post.workTogether.fluid}
          />

          <TrustBlock
            copy={post.trustedPartnersCopy}
            trustedPartnersList={post.trustedPartnersList}
          />

          <Footer
            logo={post.logoMollieBlack.file.url}
            copyright={post.footerCopyright}
            iconList={post.footerIconsList}
          />
        </div>
      </Layout>
    )
  }
}

export default MolliePage

export const pageQuery = graphql`
  query molliePageQuery($slug: String!) {
    contentfulMollieTemplate(slug: { eq: $slug }) {
      title
      slug
      namePartner
      websitePartner
      logoMollieBlack {
        file {
          url
        }
      }
      logoMollieWhite {
        file {
          url
        }
      }
      logoPartnerBlack {
        file {
          url
        }
      }
      logoPartnerWhite {
        file {
          url
        }
      }
      landingCopy
      whatTitleCopy
      whatCopy {
        childMarkdownRemark {
          html
        }
      }
      whatImage {
        title
        description
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      whyTitleCopy
      whyCopy
      whyImageLeft {
        title
        description
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      whyImageRight {
        title
        description
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      countMollie
      countMollieCopy {
        childMarkdownRemark {
          html
        }
      }
      countMollieBackground {
        title
        description
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      countPartner
      countPartnerCopy {
        childMarkdownRemark {
          html
        }
      }
      countPartnerBackground {
        title
        description
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      collaborationCopy
      collaborationBackground {
        title
        description
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      profileMollieCopy {
        childMarkdownRemark {
          html
        }
      }
      profileMolliePortrait {
        title
        description
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      profilePartnerCopy {
        childMarkdownRemark {
          html
        }
      }
      profilePartnerPortrait {
        title
        description
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      questionLandingCopy
      questionLandingBackground {
        title
        description
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      qAndABlockList {
        id
        showNumber
        question {
          childMarkdownRemark {
            html
          }
        }
        answer {
          childMarkdownRemark {
            html
          }
        }
      }
      referralBlockList {
        id
        referralText {
          childMarkdownRemark {
            html
          }
        }
        referralSignature {
          childMarkdownRemark {
            html
          }
        }
        referralLandingImage {
          title
          description
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyContentfulFluid
          }
        }
        referralThumbnail {
          title
          description
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        referralCtaText
        referralCtaLink
      }
      becomePartnerCopy
      newestFeaturesTitle
      newestFeaturesBlockList {
        id
        showNumber
        question {
          childMarkdownRemark {
            html
          }
        }
        answer {
          childMarkdownRemark {
            html
          }
        }
      }
      workTogetherCopy
      workTogether {
        title
        description
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      trustedPartnersCopy
      trustedPartnersList {
        name
        hyperlink
        thumbnail {
          title
          description
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
      footerCopyright
      footerIconsList {
        title
        hyperlink
        icon {
          title
          description
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`

import React from 'react';
import gsap from 'gsap';

import { resizeModule } from '../modules/ResizeModule';
import { smoothScrollModule } from '../modules/SmoothScrollModule';

class SmoothComponent extends React.Component {

    constructor(props) {
        super(props);

        this._bindAll(this);
    }

    _bindAll(arg) {
        this._onResize = this._onResize.bind(arg);
        this._onScroll = this._onScroll.bind(arg);
    }

    componentDidMount() {
        this.ui = {
            el: document.querySelector(this.props.el),
            content: document.querySelector(this.props.content)
        }

        this._setupListeners();
        this._setHeight();
    }

    _setupListeners() {
        resizeModule.add('resize', this._onResize);
        smoothScrollModule.add('scroll', this._onScroll);
        gsap.delayedCall(1, this._onResize);
    }

    _setHeight() {
        let height = this.ui.content.offsetHeight;
        gsap.set(this.ui.el, { height: height + 'px'});
    }
    
    _setOffset() {
        gsap.set(this.ui.content, { y: -1 * smoothScrollModule.y + 'px'});
    }

    /*
    * Handlers
    */
    _onResize() {
        this._setHeight();
        this._setOffset();
    }

    _onScroll() {
        this._setOffset();
    }

    render() {
        return (
            <></>
        )
    }

}

export default SmoothComponent;